/* Accent scale tokens: https://www.radix-ui.com/themes/docs/theme/color */
.vercel {
  [cmdk-item] {
    content-visibility: auto;

    margin-left: calc(-1 * var(--space-2));

    cursor: pointer;
    height: var(--space-8);
    border-radius: var(--radius-1);
    font-size: var(--font-size-2);
    font-weight: var(--font-weight-regular);
    display: flex;
    align-items: center;
    gap: var(--space-2);
    padding: 0 var(--space-2);
    color: var(--gray-11);
    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;

    &[data-selected='true'] {
      background: var(--accent-2);
      color: var(--accent-11);
    }

    &[data-disabled='true'] {
      color: var(--gray-8);
      cursor: not-allowed;
    }

    &:active {
      transition-property: background;
      background: var(--accent-1);
    }

    & + [cmdk-item] {
      margin-top: var(--space-1);
    }

    svg {
      width: var(--space-5);
      height: var(--space-5);
      color: var(--accent-11);
    }

    &[data-disabled='true'] svg {
      color: var(--gray-8);
    }
  }

  [cmdk-list] {
    height: 630px;
    max-height: 400px;
    overflow: auto;
    overscroll-behavior: contain;
    transition: 100ms ease;
    transition-property: height;
  }

  [cmdk-separator] {
    height: 1px;
    width: 100%;
    background: var(--gray-5);
    margin: var(--space-1) 0;
  }

  *:not([hidden]) + [cmdk-group] {
    margin-top: var(--space-2);
  }

  [cmdk-group-heading] {
    user-select: none;
    font-size: var(--font-size-2);
    color: var(--gray-12);
    font-weight: var(--font-weight-bold);
    letter-spacing: -0.025em;
    display: flex;
    align-items: center;
    margin-bottom: var(--space-2);
  }

  [cmdk-empty] {
    font-size: var(--font-size-2);
    font-weight: var(--font-weight-medium);
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--space-8);
    white-space: pre-wrap;
    color: var(--gray-11);
  }
}
